import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../config/app.config';
import { RestService } from '../core/rest.service';

@Injectable()
export class ArticleService {

  constructor(private restService: RestService) { }

  getMyArticles(articleInfo) {
    return this.restService.get(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/`, articleInfo).pipe(map((res: any) => res));
  }
  getArticlesById(articleId) {
    return this.restService.get(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}`, null).pipe(map((res: any) => res));
  }
  updateArticle(articleId: string, articleInfo) {
    return this.restService.put(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}`, articleInfo, true).pipe(map((res: any) => res));
  }
  getArticleTasks(articleId) {
    return this.restService.get(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/workflows/`, null).pipe(map((res: any) => res));
  }
  public uploadReviewerManuscript(articleId, reviewerId,cycle, formData) {
    return this.restService
      .post(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/reviewers/${reviewerId}/manuscript/${cycle}`, formData, null, true, false).
      pipe(map((res: any) => res));
  }
  public getReviewerManuscripts(articleId) {
    return this.restService
      .get(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/reviewers/manuscripts`, null).
      pipe(map((res: any) => res));
  }
  archive(articleId: string) {
    return this.restService.post(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/archive`, {}, null).pipe(map((res: any) => res));
  }
  unarchive(articleId: string) {
    return this.restService.post(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/unarchive`, {}, null).pipe(map((res: any) => res));
  }
  public getReviewerManuscript(articleId, reviewerId,cycle,) {
    return this.restService
      .get(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/reviewers/${reviewerId}/manuscript/${cycle}/details`, null).
      pipe(map((res: any) => res));
  }
  update(articleId: string, articleInfo) {
    return this.restService.put(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}`, articleInfo, false).pipe(map((res: any) => res));
  }
  getReviewQuestionnaireWithResponses(articleId,reviewerId,questionnaireId) {
    return this.restService
    .get(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/reviews/${reviewerId}/questionnaire/${questionnaireId}`, null).
    pipe(map((res: any) => res));
  }
  saveReviewQuestionResponses(articleId,reviewerId,questionnaireId,questionAndResponses) {
    return this.restService
    .post(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/reviews/${reviewerId}/questionnaire/${questionnaireId}`,questionAndResponses, null).
    pipe(map((res: any) => res));
  }
  submitReviewQuestionResponses(articleId,reviewerId,body) {
    return this.restService
    .put(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/reviews/${reviewerId}`,body, null).
    pipe(map((res: any) => res));
  }
  summarizeJuryScores() {
    return this.restService
    .post(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/summarize-juryscores`,{}, null).
    pipe(map((res: any) => res));
  }
  getJuryScoreBoard() {
    return this.restService
    .get(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/jury-scoreboard`, null).
    pipe(map((res: any) => res));
  }
  getJuryScoreBoardAsExcel(downloadType) {
    return this.restService
    .get(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/jury-scoreboard?download=${downloadType}`, false, true, true).
    pipe(map((res: any) => res));
  }
  public getManuscriptContent(articleId, reviewerId,cycle) {
    return this.restService
      .get(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/reviewers/${reviewerId}/manuscript/${cycle}`, null, false, true, true).
      pipe(map((res: any) => res));
  }

  public getAllAttachments(articleId) {
    return this.restService
      .get(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/attachments`, null).
      pipe(map((res: any) => res));
  }
  public getAttachmentContent(articleId, filename) {
    return this.restService
      .get(`${AppConfig.BASE_URL_V09}/journals/${AppConfig.JOURNAL_ID}/articles/${articleId}/attachments/${filename}`, null, false, true, true).
      pipe(map((res: any) => res));
  }

  public getArticles(page) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article', { page }, null).
      pipe(map((res: any) => res));
  }
  public createArticle(data, id) {
    return this.restService
      .post(AppConfig.BASE_URL_V1 + '/article/' + id, data, null, true, false).
      pipe(map((res: any) => res));
  }
  public getAllArticleVersions(id) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/versions/' + id, true).
      pipe(map((res: any) => res));
  }
  public getArticleDetails(id, journalId) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/' + id + '/' + journalId, null).
      pipe(map((res: any) => res));
  }
  public archiveArticle(journalId, manuscriptId) {
    return this.restService
      .post(AppConfig.BASE_URL_V1 + '/article/archiveArticle/' + journalId + '/' + manuscriptId, null).
      pipe(map((res: any) => res));
  }
  public getArticleConversionLog(journalId, manuscriptId) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/conversionLog/' + journalId + '/' + manuscriptId, null, false, false, true).
      pipe(map((res: any) => res));
  }
  public saveArticleContent(nodeId, content, majorVersion) {
    return this.restService
      .put(AppConfig.BASE_URL_V1 + '/article/content/' + nodeId + '?majorVersion=' + majorVersion, content, false, true, true).
      pipe(map((res: any) => res));
  }
  public getArticleContent(nodeId) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/content/node/' + nodeId, null, false, false, true).
      pipe(map((res: any) => res));
  }
  public getArticleContentByVersion(nodeId, ver) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/versionContent/' + nodeId + '/' + ver, null, false, true).
      pipe(map((res: any) => res));
  }
  public getArticleDiffContentByVersion(nodeId, selectedVer, Diffver) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/htmlFileDifference/' + nodeId + '/' + selectedVer + '/' + Diffver, null, false, true, true).
      pipe(map((res: any) => res));
  }
  public saveAuthorsAndInvitedAuthors(id, data) {
    return this.restService
      .put(AppConfig.BASE_URL_V1 + '/article/' + id, data).
      pipe(map((res: any) => res));
  }
  public updateArticleProperties(id, data) {
    return this.restService
      .put(AppConfig.BASE_URL_V1 + '/article/' + id, data).pipe(map((res: any) => res));
  }
  public uploadAttachment(journalId, articleId, documentType, data) {
    return this.restService
      .post(AppConfig.BASE_URL_V1 + '/article/articleUploads/' + journalId + '/' + articleId + '/' + documentType, data, false, true).
      pipe(map((res: any) => res));
  }
 
  public uploadPaymentAttachment(journalId, manuscript, articleId, taskId, date, amount, transactionNumber , formdata) {
    return this.restService
      // tslint:disable-next-line:max-line-length
      .post(AppConfig.BASE_URL_V1 + '/article/paymentUpload/' + journalId + '/' + manuscript + '/' + articleId + '/' + taskId + '?paymentDate=' + date + '&transactionNumber=' + transactionNumber + '&paidAmount=' + amount, formdata, false, true).
      pipe(map((res: any) => res));
  }
  public getPaymentAttachments(journalId, manuscript) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/paymentUpload/' + journalId + '/' + manuscript, null).
      pipe(map((res: any) => res));
  }
  public submitArticle(journalId, articleId, documentId, data) {
    return this.restService
      .post(AppConfig.BASE_URL_V1 + '/article/submit/' + journalId + '/' + articleId + '/' + documentId, data, false).
      pipe(map((res: any) => res));
  }
  public submitArticleForPeerReview(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/completePrAssignReviewers/' + taskId, data).
      pipe(map((res: any) => res));
  }
  public reSubmitArticle(taskId, data, documentId) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/revisedArticleSubmit/' + taskId + '/' + documentId, data).
      pipe(map((res: any) => res));
  }
  public peerReviewReSubmitArticle(taskId, data, documentId) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/peerReviewRevisedArticleSubmit/' + taskId + '/' + documentId, data).
      pipe(map((res: any) => res));
  }
  public PeerReviewInvitationSubmit(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/completePRInvitationDecision/' + taskId, data).
      pipe(map((res: any) => res));
  }
  public actualPeerReviewSubmit(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/completePROverrideInvitationDecision/' + taskId, data).
      pipe(map((res: any) => res));
  }
  public updatePRPeerReviewTask(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/updatePRPeerReviewTask/' + taskId, data).
      pipe(map((res: any) => res));
  }
  public updatepanelReviewTask(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL_V1 + '/workflow/updatePanelReviewTask/' + taskId, data).
      pipe(map((res: any) => res));
  }
  public updateReReviewTask(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL_V1 + '/workflow/updateReReviewTask/' + taskId, data).
      pipe(map((res: any) => res));
  }
  public completePRPeerReviewTask(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/completePRPeerReviewTask/' + taskId, data).
      pipe(map((res: any) => res));
  }
  public completePanelReviewTask(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL_V1 + '/workflow/completePanelReviewTask/' + taskId, data).
      pipe(map((res: any) => res));
  }
  public completeReReviewTask(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL_V1 + '/workflow/completeReReviewTask/' + taskId, data).
      pipe(map((res: any) => res));
  }
  public completePRReReview(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/completePRReReview/' + taskId, data).
      pipe(map((res: any) => res));
  }
  public savePRConsolidateReviewTask(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/savePRConsolidateReviewTask/' + taskId, data).
      pipe(map((res: any) => res));
  }
  public completePRConsolidateReviewTask(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/completePRConsolidateReviewTask/' + taskId, data, null).
      pipe(map((res: any) => res));
  }
  public completePREditorReview(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/completePREditorReview/' + taskId, data, null).
      pipe(map((res: any) => res));
  }
  public completePRAssignCopyeditorTask(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/completePRAssignCopyeditorTask/' + taskId, data).
      pipe(map((res: any) => res));
  }
  public completePRCopyEditTask(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/completePRCopyEditTask/' + taskId, data).
      pipe(map((res: any) => res));
  }
  public completePRCEAuthorReviewTask(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/completePRCEAuthorReviewTask/' + taskId, data).
      pipe(map((res: any) => res));
  }
  public completePRFinalReviewTask(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/completePRFinalReviewTask/' + taskId, data).
      pipe(map((res: any) => res));
  }
  public completePRCEEditorReview(taskId, data) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/completePRCEEditorReview/' + taskId, data).
      pipe(map((res: any) => res));
  }
  public getArticlesForJournal(data) {
    return this.restService
      .get(AppConfig.BASE_URL + '/editaware/article',data, true).
      pipe(map((res: any) => res));
  }
  public searchArticle(page,keyword, journalId) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article', { keywords: keyword, journalId, page }, true).
      pipe(map((res: any) => res));
  }
  public uploadManuscript(manuscriptNodeId, manuscriptId, data) {
    return this.restService
      .put(AppConfig.BASE_URL + '/editaware/article/uploadArticleManuscript/' + manuscriptNodeId +'/' + manuscriptId,  data, false, true, true).
      pipe(map((res: any) => res));
  }
  public deleteAuthor(userId, nodeId) {
    return this.restService
      .delete(AppConfig.BASE_URL_V1 + '/article/colloborator/' + userId + '/' + nodeId, true).
      pipe(map((res: any) => res));
  }
  public updateAuthor(userId, nodeId) {
    return this.restService
      .put(AppConfig.BASE_URL_V1 + '/article/colloborator/' + userId + '/' + nodeId, true, true, true).
      pipe(map((res: any) => res));
  }
  public getArticlePdf(articleId, nodeId) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/downloadAsPdf/' + articleId + '/' + nodeId, null, false, true, true).
      pipe(map((res: any) => res));
  }
  public getArticleHTML( articleId, nodeId) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/downloadAsHtml/'  + articleId + '/' + nodeId, null, false, true, true).
      pipe(map((res: any) => res));
  }
  public getArticleDocs( articleId, nodeId) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/downloadAsWord/'  + articleId + '/' + nodeId, null, false, true, true).
      pipe(map((res: any) => res));
  }
  public startInitialReviewOnClick(articleId, journalId) {
    return this.restService
      .post(AppConfig.BASE_URL + '/editaware/article/startInitialReview/' + articleId + '/' + journalId, true).
      pipe(map((res: any) => res));
  }
  public startPeerReviewOnClick(articleId, journalId) {
    return this.restService
      .post(AppConfig.BASE_URL + '/editaware/article/startPeerReview/' + articleId + '/' + journalId, true).
      pipe(map((res: any) => res));
  }
  public getPlagiarismDetails(nodeId, articleId, journalId) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/palagrism/' + nodeId + '/' + articleId + '/' + journalId, null, true).
      pipe(map((res: any) => res));
  }
  public getPlagiarismDetailsFromAuthor(maserXmlNodeId, articleNodeId) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/plagiarism/' + maserXmlNodeId + '/' + articleNodeId, null, true).
      pipe(map((res: any) => res));
  }
  public getPlagiarismForEditors(maserXmlNodeId, articleNodeId) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/plagiarismForEditors/' + maserXmlNodeId + '/' + articleNodeId, null, true).
      pipe(map((res: any) => res));
  }
  public getPlagiarism(type, maserXmlNodeId, articleNodeId) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/' + type + '/' + maserXmlNodeId + '/' + articleNodeId, null, false).
      pipe(map((res: any) => res));
  }
  public getPlagiarismReport(plagiarismReportId, articleNodeId) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/getPlagiarismReport/' + plagiarismReportId + '/' + articleNodeId, false).
      pipe(map((res: any) => res));
  }

  public uploadArticleImage(journalId, nodeName, type, data) {
    return this.restService
      // tslint:disable-next-line:max-line-length
      .post(AppConfig.BASE_URL + '/uploadCKEditorImages?journalId=' + journalId + '&nodeName=' + nodeName + '&type=' + type, data, null, true, true).
      pipe(map((res: any) => res));
  }
  public getArticleAssets(journalId, nodeId) {
    return this.restService
      .get(AppConfig.BASE_URL + '/assestsDetails', { journalId, nodeId }, null).
      pipe(map((res: any) => res));
  }
  public getArticleAssetz(journalId, nodeId) {
    return this.restService
      .get(AppConfig.BASE_URL + '/assestsDetails', { journalId, nodeId }, null).
      pipe(map((res: any) => res));
  }
  public postComment(journalId, manuscriptId, data) {
    return this.restService
      .post(AppConfig.BASE_URL_V1 + '/article/comments/internalComment' + '/' + journalId + '/' + manuscriptId, data).
      pipe(map((res: any) => res));
  }
  public getComments(journalId, manuscriptId) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/comments/internalComment' + '/' + journalId + '/' + manuscriptId, null).
      pipe(map((res: any) => res));
  }
  public changeArticleStatus(status, nodeId) {
    return this.restService
      .put(AppConfig.BASE_URL_V1 + '/article/status/' + status + '/' + nodeId, true, true, true).
      pipe(map((res: any) => res));
  }
  public getBibTex(blibText) {
    const data = {
      blibText,
    };
    return this.restService
      .post(AppConfig.BASE_URL + '/api/fetchCitationFromBibtex', data).
      pipe(map((res: any) => res));
  }
  public getArticleDownload(nodeId) {
    return this.restService
      .get(AppConfig.BASE_URL_V1 + '/article/content/download/' + nodeId, null, false, true, true).
      pipe(map((res: any) => res));
  }

public articleContentLockMode(nodeId, userId) {
  return this.restService
    .get(AppConfig.BASE_URL_V1 + '/article/editArticleContent' + '/' + nodeId + '/' + userId, null, false, true, false).
    pipe(map((res: any) => res));
}
public articleContentUnlockMode(nodeId) {
  return this.restService
    .post(AppConfig.BASE_URL_V1 + '/article/unlockArticle' + '/' + nodeId, true).
    pipe(map((res: any) => res));
}
public lockArticle(nodeId) {
  return this.restService
    .post(AppConfig.BASE_URL_V1 + '/article/lockArticle/'  + nodeId , true).
    pipe(map((res: any) => res));
}
public articleLockStatus(nodeId) {
  return this.restService
    .get(AppConfig.BASE_URL_V1 + '/article/nodeDetails' + '/' + nodeId, true).
    pipe(map((res: any) => res));
}
public getDashboardArticleReports(journalId, type) {
  return this.restService
    .get(`${AppConfig.BASE_URL_V1}/dashboard/dashboardArticleReports?journalId=${journalId}&${type}=true`, true).
    pipe(map((res: any) => res));
}
public getDashboardUserReports(type) {
  return this.restService
    .get(`${AppConfig.BASE_URL_V1}/dashboard/dashboardUserReports?${type}=true` ,true).
    pipe(map((res: any) => res));
}
public getDashboardReport(data) {
  return this.restService
    .post(`${AppConfig.BASE_URL_V1}/dashboard/report`, data, null, true, true, true).
    pipe(map((res: any) => res));
}
public acceptOrDeclineCollaborationInvitation(data) {
  return this.restService
    .post(AppConfig.BASE_URL + '/editaware/article/acceptOrDeclineCollaborationInvitation', data, null).
    pipe(map((res: any) => res));
}
public getAcceptedArticles(journalName) {
  return this.restService
    .get(AppConfig.BASE_URL + '/editaware/issues/searchAcceptedArticles/' + journalName, null).
    pipe(map((res: any) => res));
}
public searchAcceptedArticles(journalName, key) {
  return this.restService
    .get(AppConfig.BASE_URL + '/editaware/issues/searchArticles/' + journalName + '/' + key, null).
    pipe(map((res: any) => res));
}
public deleteLineComment(nodeId) {
  return this.restService
    .delete(AppConfig.BASE_URL + '/editaware/issues/' + nodeId, true).
    pipe(map((res: any) => res));
}
public deleteAcceptedPRReviewer(taskId, userId) {
  return this.restService
    .get(AppConfig.BASE_URL + '/editaware/article/deleteAcceptedPRReviewer/' + taskId + '/' + userId , null , false).
    pipe(map((res: any) => res));
}
public getEditors(journalId) {
  return this.restService
    .get(AppConfig.BASE_URL + '/editaware/group/editor/' + journalId, null).
    pipe(map((res: any) => res));
}
public reassignTask(taskId, userId, articleNodeId) {
  return this.restService
    .get(AppConfig.BASE_URL + '/editaware/article/reassignTask/' + taskId + '/' + userId + '/' + articleNodeId, null).
    pipe(map((res: any) => res));
}
public getWorkflowCommentHistory(journalId, manuscriptId) {
  return this.restService
    .get(AppConfig.BASE_URL_V1 + '/article/comments/workflow/' + journalId + '/' + manuscriptId , false).
    pipe(map((res: any) => res));
}
public workflowHistory(nodeId, journalId, manuscriptId, cycle) {
  return this.restService
    // tslint:disable-next-line:max-line-length
    .get(AppConfig.BASE_URL_V1 + '/article/comments/workflow/history/' + nodeId + '/' + journalId + '/' + manuscriptId + '/' + cycle , null).
    pipe(map((res: any) => res));
}
public getPaymentDetails() {
  return this.restService
    .get(AppConfig.BASE_URL_V1 + '/article/paypal/instructions', null).
    pipe(map((res: any) => res));
}
}
